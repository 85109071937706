// public
export const HOME_ROUTE = '/'
export const CAR_ROUTE = '/infoCar/:carId'
export const SPONSOR_LIST_ROUTE = '/sponsorList'
export const REQUISITES_ROUTE = '/requisites'
export const REPAIR_ROUTE = '/repair'
export const GRATITUDE_ROUTE = '/gratitude'
// private
export const LOGIN_ROUTE = '/login'
export const ADMIN_ROUTE = '/admin'
export const EDIT_SPONSOR_ROUTE = '/sponsor/:sponsorId'
export const ADD_SPONSOR_ROUTE = '/sponsor/add'
export const EDIT_CAR_ROUTE = '/car/:carId'
export const ADD_CAR_ROUTE = '/car/add'
export const EDIT_REPAIR_CAR_ROUTE = '/carRepair/:carId'
export const ADD_REPAIR_CAR_ROUTE = '/carRepair/add'
export const EDIT_USER_ROUTE = '/user/:userId'
export const ADD_USER_ROUTE = '/user/add'
export const ADD_GRATITUDE_ROUTE = '/gratitude/add'
export const EDIT_GRATITUDE_ROUTE = '/gratitude/:gratitudeId'
